import React, { useState } from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/Seo/Seo"
import Amplify, { Auth } from "aws-amplify"
import { useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faUser,
  faLock,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons"
import "./get-started.css"

const GetStartedPage = function () {
  let [email, setEmail] = useState("")
  let [name, setName] = useState("")
  let [password, setPassword] = useState("")
  let [errorMsg, setErrorMsg] = useState("")
  let [selectedTypeOption, setSelectedTypeOption] = useState("eventPlatform")

  let [isSubmitting, setIsSubmitting] = useState(false)
  let [isSubmitSuccess, setIsSubmitSuccess] = useState(false)

  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "developer.png" }) {
        childImageSharp {
          fixed(width: 380, height: 330) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  Amplify.configure({
    Auth: {
      region: "eu-west-1",
      userPoolId: "eu-west-1_k9E8HGvOh",
      userPoolWebClientId: "23q24vt572g8n04qoga9fph504",
    },
  })

  const signUp =  async function () {
      setErrorMsg("");
      setIsSubmitting(true);

      try {
        const user = await Auth.signUp({
          username: email,
          password,
          attributes: {
            email: email,
          },
        })
        console.log("user", { user })
        setIsSubmitSuccess(true);
      } catch (error) {
        console.log(error)

        setIsSubmitting(false);

        if (error && error.code == "InvalidPasswordException") {
          return setErrorMsg("Please enter a valid password");
        }

        if (error && error.code == "UsernameExistsException") {
          return setErrorMsg("It looks like you've already signed up");
        }

        if (error.log) {
          setErrorMsg(error.log.replace('Username', 'Email'));
        }
      }
  }

  return (
    <Layout header={true} footer={true}>
      <SEO title="Home" />
      <section className="section">
        <div className="container">
          <div className="columns content">
            <div className="column is-medium is-two-thirds">
              <h1>Sign up</h1>
              <p>
                We're in a limited beta phase at the moment and we're looking
                for partners to launch with. Sign up and we'll be in touch.
              </p>
              <div className="columns content">
                <div className="column is-medium">
                  {errorMsg && (
                    <article className="message is-warning">
                      <div className="message-header">
                        <p>Something went wrong!</p>
                      </div>
                      <div className="message-body">{errorMsg}</div>
                    </article>
                  )}
                  {isSubmitSuccess &&
                    <div>Thank you, we'll be in touch!</div>
                  }
                  {!isSubmitting && !isSubmitSuccess &&
                  <div className='form'>
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Name</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-icons-left">
                            <input
                              className="input"
                              required
                              autoComplete="name"
                              onChange={ev => {
                                setName(ev.target.value)
                              }}
                              type="text"
                              value={name}
                            />
                            <span className="icon is-small is-left">
                              <FontAwesomeIcon icon={faUser} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Email</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-icons-left">
                            <input
                              className="input"
                              type="text"
                              autoComplete="email"
                              value={email}
                              required
                              onChange={ev => {
                                setEmail(ev.target.value)
                              }}
                              placeholder="hello@there.com"
                            />
                            <span className="icon is-small is-left">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="field is-horizontal">
                      <div className="field-label is-normal">
                        <label className="label">Password</label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control has-icons-left">
                            <input
                              className="input"
                              type="password"
                              required
                              onChange={ev => {
                                setPassword(ev.target.value)
                              }}
                              value={password}
                            />
                            <span className="icon is-small is-left">
                              <FontAwesomeIcon icon={faLock} />
                            </span>
                          </div>
                          <p className="help is-success">
                            Min length 8 characters, must include a number,
                            uppercase and lowercase letter
                          </p>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="field is-horizontal">
                      <div className="field-label">
                        <label className="label">For</label>
                      </div>
                      <div className="field-body">
                        <div className="field is-narrow">
                          <div className="control">
                            <input
                              className="is-checkradio"
                              type="radio"
                              onClick={ev => {
                                setSelectedTypeOption("eventPlatform")
                              }}
                              checked={selectedTypeOption === "eventPlatform"}
                            />
                            <label
                              onClick={ev => {
                                setSelectedTypeOption("eventPlatform")
                              }}
                            >
                              Event platform
                            </label>
                            <input
                              className="is-checkradio"
                              type="radio"
                              onClick={ev => {
                                setSelectedTypeOption("eventOrganiser")
                              }}
                              checked={selectedTypeOption === "eventOrganiser"}
                            />
                            <label
                              onClick={ev => {
                                setSelectedTypeOption("eventOrganiser")
                              }}
                            >
                              Event organiser
                            </label>
                            <input
                              className="is-checkradio"
                              type="radio"
                              onClick={ev => {
                                setSelectedTypeOption("developer")
                              }}
                              checked={selectedTypeOption === "developer"}
                            />
                            <label
                              onClick={ev => {
                                setSelectedTypeOption("developer")
                              }}
                            >
                              Developer
                            </label>
                            <input
                              className="is-checkradio"
                              type="radio"
                              onClick={ev => {
                                setSelectedTypeOption("other")
                              }}
                              checked={selectedTypeOption === "other"}
                            />
                            <label
                              onClick={ev => {
                                setSelectedTypeOption("other")
                              }}
                            >
                              Other
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="field is-horizontal">
                      <div className="field-label">
                        <label className="label"></label>
                      </div>
                      <div className="field-body">
                        <div className="field">
                          <input
                            className="is-checkradio"
                            id="exampleCheckbox"
                            type="checkbox"
                            name="exampleCheckbox"
                            checked
                          />
                          <label>I agree to the terms and conditions</label>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="field is-horizontal">
                      <div className="field-label"></div>
                      <div className="field-body">
                        <div className="field">
                          <div className="control">
                            <button
                              className="button is-primary"
                              onClick={() => {
                                signUp()
                              }}
                            >
                              Sign up to hear from us &nbsp;
                                <FontAwesomeIcon
                                  icon={faPaperPlane}
                                />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                    }
                </div>
              </div>
            </div>
            <div className="column is-hidden-mobile">
              <Img fixed={data.file.childImageSharp.fixed} />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default GetStartedPage
